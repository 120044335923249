.Msg_tel_escura{
    width: 100vw;
    height: 100%;
    position: fixed;
    display: flex;
    margin: 0 auto;
    top:0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
    animation: open_Backgroun 0.15s linear;
}

.Msg_tel_escura_close{
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 3;
    animation: open_Backgroun_close 0.35s linear;
}

@keyframes open_Backgroun {
    0%{
        background-color: rgba(0, 0, 0, 0.0);
    }
}

@keyframes open_Backgroun_close{
    0%{
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.Msg_container_pr{
    width: 95%;
    max-width: 500px;
    position: absolute;
    bottom: 10px;
    background-color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    display: block;
    opacity: 1;
    animation: open_msg 0.4s ease-in-out;
}

.Msg_container_pr_close{
    width: 95%;
    max-width: 500px;
    position: absolute;
    bottom: -25%;
    background-color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    display: block;
    opacity: 0;
    animation: close_msg 0.4s ease-in-out;
}
@keyframes open_msg {
    0%{
        bottom: -25%;
        opacity: 0;
    }
}
@keyframes close_msg {
    0%{
        bottom: 10px;
        opacity: 1;
    }
}
.Mdg_bloc01{
    margin-top: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Mdg_bloc01 svg{
    margin-right: 10px;
}
.Mdg_bloc01 h3{
    margin: 0 ;
    margin-top: 7px;
    margin-bottom: 5px;
}

.Msg_true{
    fill: rgb(11, 198, 11);
}

.Msg_false{
    fill: rgb(241, 65, 65);
}


.Msg_conteudoMsg{
    width: 90%;
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 35px;
}

.Msg_container_btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;

    margin-bottom: 10px;

    box-sizing: border-box;
}
.Msg_container_btn button{
    width: 50%;
    max-width: 150px;
    height: 40px;
    margin-right: 20px;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 600;
}

.telaInfor_instalacao{
    width: 95%;
    max-width: 600px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
}

.buttonInstall{
    width: 50%;
    max-width: 150px;
    height: 35px;
    border: none;
    border-radius: 5px;
    right: 2px;
    background-color: rgb(49, 246, 128);
    margin-left: 50px;
    font-weight: 600;
    cursor: pointer;
    margin-right:20px ;
    transition: all 0.11s ease-in-out;
}
.buttonInstall:hover{
    transform: scale(1.05);
}

.buttonDisp{
    border: none;
    background-color: transparent;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.502);
    cursor: pointer;
    transition: all 0.12s ease-in-out;
}

.buttonDisp:hover{
    color: rgba(162, 6, 6, 0.802);
}

.imgLogoRtc{
    animation: rtcLogo 2.5s linear infinite;
}

@keyframes rtcLogo {
    0%{
        transform: rotate(-360deg);
    }
}