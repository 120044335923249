.Container_Login {
    width: 100%;
    background-color: #031A26;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .Login_second {
    width: 80%;
    max-width: 350px;
    height: 350px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    display: block;
    position: relative;
  }
  
  .container_iconUser {
    position: relative;
  
    width: 100%;
    height: 80px;
  }
  
  .Icon_User {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    top: -50px;
    right: 32%;
    box-shadow: 0 4px 10px #26262681;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .Icon_User svg {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: #262626;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  
  #root {
    width: 100vw;
    height: 100vh;
  }
  
  .Container_Dados_User {
    width: 80%;
  
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .input-container {
    position: relative;
    margin-bottom: 20px;
  }
  
  .input-label {
    position: absolute;
    top: 8px;
    left: 10px;
    pointer-events: none;
    transition:
      top 0.3s,
      font-size 0.3s;
  }
  
  .input-filled .input-label {
    top: -12px;
    font-size: 13px;
  }
  
  .Container_Login input {
    width: 100%;
    padding: 8px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #262626;
    box-sizing: border-box;
    outline: none;
  }
  
  .tst {
    animation: rotates 5s linear infinite;
  }
  @keyframes rotates {
    0%,
    100% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .container_Button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .btn_Login {
    width: 85%;
    max-width: 250px;
    height: 40px;
    background-color: #0DE070;
    border-radius: 8px;
    border: none;
    margin-bottom: 10px;
    color: rgb(0, 0, 0) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }