
body{
  margin:0 auto;
  font-family:'Roboto', sans-serif;
  background-color:rgb(3, 26, 38);
  overflow-x:hidden ;
  user-select: none;
}


.dados_info{
  width:95vw;
  height:12px;
  display:flex;
  margin:0 auto;
  padding:2px;
  margin-top:5px;
  color: white;
  margin-bottom:5px;
  margin-left:10px;
  position: relative;

}
.dados_info svg{
  fill:#212121;
  width:16px;
  height:16px;
  margin-left:5px;
}
.dados_info span{
  font-size:clamp(12px,1.8vw,16px);
  font-family:'Roboto', sans-serif;
  font-weight:400;
  color:#212121;
  margin-bottom:5px;
}
.TurnoAnimation{

  animation: trAnimation 0.8s linear infinite;
}
@keyframes trAnimation {
  0%, 100%{

    transform: rotate(2deg);
  }25%{

    transform: rotate(1deg);
  }50%{

    transform: rotate(-2deg);
  }
}
.container_00_Maquinas{
  width:97vw;
  margin:0 auto;
  margin-top:10px;
  display:flex;
  flex-direction: row;
  align-items: stretch;

  flex-wrap:wrap;
  position:relative;
 /*  border:1px solid #f901f0; */
  margin-bottom: 65px;
}
.container_01_Maquinas{
  width:100vw;
  min-width: 550px;
  display:grid;
  justify-content: center;
  align-items: center;
  position:relative;
/*   border: 1px solid red !important; */
  flex:1;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  padding: 4px 4px;
}

.ContaierMaq{
  color:rgb(23, 23, 23);
  width:100%;
  margin:0 auto;
  background-color:rgba(255, 255, 255, 0.911); 
  border-radius:20px;
  display:block;
  min-width:250px;
  box-sizing:border-box;

}
.Maquinas{
  width:100%;
  position:relaitve;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
}

.ball{
  font-family:'Roboto', sans-serif;
  width:58px;
  height:58px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  background-color:rgb(0, 190, 35);
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;
  margin:3px;
  color:white;
  font-weight:700;
  font-size:clamp(19px, 1.8vw, 24px);
  box-sizing: border-box;
  transition: all 0.1s linear;
}

.ball:hover{
  transform: scale(1.12);
  cursor: pointer;

}

.Legenda{
  display:flex;
  width:100%;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  margin:0 auto;
  margin-top:8px;
  box-sizing:border-box;
  font-size:clamp(12px,1.8vw,13px);
  box-sizing: border-box;
  margin-bottom: 10px;

}
.ball_lEGEND{
  width:12px;
  height:12px;
  border-radius:50%;
  margin:5px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.Quadros_Dados{
  width: 100%;
  display:flex;
  position:relative;
  justify-content: center;
  align-items: center;

}

.Quadro01{
  font-family:'Roboto', sans-serif;
  width: 100%;
  margin: 3px;
  border-radius:15px;
  height:70px;
  background-color:#ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.Quadro01 svg{
  fill: rgb(0, 190, 35);
  position: absolute;
  top: 3px;
  left: 0;
  width: 50px;
}

.Quadro01 span{
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: clamp(12px,1.8vw,14px);
}
.Quadro01 h2{
  color: #262626;
font-size: clamp(22px,1.8vw,25px);
}



.container_02_Maquinas{
  position:relative;
  width:100%;
  min-width:320px;
  display:block;
  background-color: #ffffff;
  flex: 1;
  border-radius:15px;
  margin:5px;


}

.container_02_Maquinas:nth-child(4){
  max-width: 540px;
  max-height: 350px;
  overflow-y: auto;
  min-width: 300px;
  background-color: #ffffff;
  display: block;

}
.container_02_Maquinas:nth-child(5){
  max-width: 550px;
  max-height: 350px;
  overflow-y: auto;
  min-width: 300px;

}
.Percent_Disponibilidade{
  width:60%;
  color:#262626;
  position:absolute;
  margin:0 auto;
  box-sizing:border-box;
  display:block;
}
.Label_grafico_TP{
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  margin-top:-6px;
  position:relative;
  font-size: clamp(12px,1.8vw,15px);
}
.Label_grafico_TP1{
  width:100%;
  box-sizing:border-box;
  margin-top:-6px;
  position:relative;
}
.ballTp1{
  width:50%;
  display:flex;
  color:#262626;
  font-size:clamp(11px,1.8vw,15px);
  position:relative
}
.ballTp{
  width:50%;
  display:flex;
  justify-content:center;
  align-items:center;
  color:#262626;
  font-size:clamp(12px,1.8vw,15px);
  position:relative
}
.BallTpBlue, .BallTpred{
  width:10px;
  height:10px;
  border-radius:2px;
  background-color:rgb(0,150,210);
  margin:2px;
}
.BallTgreen{
  width:10px;
  height:10px;
  border-radius:2px;
  margin:2px;
  background-color:green;
}
.BallTpred{
  background-color:rgb(210,20,10);
}

.Ds{
  border:1px solid red;
}


.graficoDisponibilidade{
  width:100%;
  height:75%;
  box-sizing:border-box;
  display:flex;
  justify-content:center;
  align-items:center;
}

.infoGrafico{
  margin:2px auto;
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;


}
.infoGrafico span{
  font-size:clamp(13px,1.8vw,15px);
  margin-top:3px;
}
.infoGrafico svg{
  position: absolute;
  top:2px;
  left:2px;
  fill:#262626;
  width:50px;
  height:50px;

}
.grafico_Disponibilidade{
  width:250px;
  height:250px;
}
.Nave_phone{
  display: none;
  
}

@media (max-width: 714px) {
  .Nave_phone{
  display: flex;
  }

}

.navMenu_Espelho{
  display: none;
}

@media (max-width: 843px) {
  .ball{
    width:40px;
    height:40px;
  }
  .container_01_Maquinas{
    min-width: 350px;
  }
  .navMenu_Espelho{
    display: flex;
    margin-top: 30px;
  }
  .container_02_Maquinas{
    min-width:160px;
  }
  .grafico_Disponibilidade{
    width:115px;
    height:115px;
  }
  .infoGrafico svg{
    position: absolute;
    top:2px;
    left:2px;
    fill:#262626;
    width:30px;
    height:30px;
  }
  .Quadro01 svg{
    position: absolute;
    top: 3px;
    left: 5px;
    width: 19px;
  }
  .dados_info svg{
    width:13px;
    height:13px;
  }
  .Quadros_Dados{
    max-width:500px;
    margin-top:5px;
    display:flex;
    position:relative;
    top:0;
  }
}

/* Aqui inicia-se os dados para mecanico que iniciou maquinas parada  */

.container_IniciouManutencao{
  max-width: 50%;
  min-width: 40%;
  flex: 1;
  display:block;
  box-sizing:border-box;
  border-radius:6px;
  position: relative;
  font-size: clamp(12px,1.8vw,13px);
  overflow: hidden;
  padding: 7px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  margin:5px
}
.contaienr_dados_MaquinaParada {
margin-left: 5px;

}
.In_NomUser{
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: clamp(14px,1.8vw,16px);
}
.Maq_Parou{
  margin:0 auto;
  margin-top: -7px;
  margin-bottom: 5px;
  max-width: 150px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: clamp(15px, 1.8vw, 20px);
  font-weight: 700;
}

.Laranja_IniciouMP{
  background-color: rgba(255, 221, 0, 0.464);
}
.vermelho_MP{
  /* animation: vermelhogradi 1.1s linear infinite; */
  background-color: rgb(247, 71, 71);
}
.Azul_MP{
  background-color: rgb(9, 152, 234);
}
.roxo_MP{
  background-color: rgb(140, 0, 240);
}
.verde_MP{
  background-color: rgb(0, 190, 35);
}

@keyframes vermelhogradi{
  0%,100%{
    background-color: rgb(255, 1, 1);
    transform: scale(1);
  }
  50%{
    transform: scale(1.03);
    background-color: rgb(152, 46, 46);
  }
}

.rotateTempo{
  animation: rotaframe 1s ease-in-out infinite;
}
@keyframes rotaframe {
  0%{
    transform: rotate(-180deg);
    fill: red;
    
  }
}
.IniciouManutencao{
  background-color: rgba(37, 251, 126, 0.523);
}

.Ordem_add_mais{
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 80px;
  border-radius:20%;
  background-color: #1bf278ff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2vw;
}

.Mg_menu_Click_style{
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 40px;
  height: 40px;
}
.Mg_menu_Click_style_open{
  border-radius: 100%;
  position: absolute;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background-color: #86868688;
  animation: clickOpen 0.2s ease-in-out;
  
}

@keyframes clickOpen {
  0%{
    background-color: rgba(121, 121, 121, 0.055);
    width: 2px;
    height: 2px;
  }
}
@keyframes clickClose {
  0%{
    background-color: #212121cb;
    width: 40px;
    height: 40px;
  }
}

/* "loaderGeral">
        <div className="loadingSpiner"> */

/* Criando o loadin para o carregamento apos 100% */

.loaderGeral{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 15;
  padding: 10px;
}

.loadingSpiner{
  width: 60px;
  height: 60px;
  background-color: transparent;
  border:5px solid rgba(0, 0, 0, 0.395);
  border-left: 5px solid rgb(39, 241, 116);
  border-top: 5px solid rgb(39, 241, 116);
  animation: spinderLoader 0.8s linear infinite;
  box-sizing: border-box;
  border-radius: 100%;
}

@keyframes spinderLoader {
  0%{
    transform: rotate(-360deg);
  }
}