.addPrev_container{
    width: 100%;
    height: 100vh;
    position: absolute;
    margin: 0 auto;
    display: block;
    background-color: #f4f4f4;
    overflow-x: hidden;
}
.addPrev_container2{
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 30%;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-color:rgb(3, 26, 38);
}

.addPrev_container3{
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 800px;
    top:0;
/*     border: 1px solid rgb(19, 144, 133); */
    box-sizing: border-box;
    margin: 0 auto;
    display: block;

}


.Add_arquivoPrev{
    position: relative;
    z-index: 1;
    width: 95%;
    box-sizing: border-box;
/*     border: 1px solid rgb(255, 0, 0); */
    margin:0 auto;
    padding-bottom: 70px;
    color: rgb(0, 0, 0);
    display: block;
    margin:0 auto;
    margin-top: 20px;
}
.Prev_AddArquivo label{
    padding: 10px ;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.15s linear ;
    font-size: 14px;
    max-width: 130px;
    margin: 0 'auto' ;
}
.Prev_AddArquivo label:hover{
    transform: scale(1.05);
}

#ArquivoSelecionado{
    font-size: 12px;
    margin-top: 15px;
    width: 100%;

}
.btnEnviar_File{
margin-top: 15px;
width: 100%;
box-sizing: border-box;
display: flex;
justify-content: flex-end;
}
.btnEnviar_File button{
    height: 35px;
    width: 50%;
    max-width: 150px;
    border-radius: 5px;
    border:none;
}

/* Selecionar usuario para enviar as preventivas */

.blackSelectUser{
    width: 100%;
    position: fixed;
    height: 100%;
    display: flex;
    top: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
    align-items: center;
}
.containerSelectuser_Prev{
    background-color: rgb(237, 237, 237);
    padding: 15px 15px;
    width: 50%;
    max-width: 250px;
    max-height: 250px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow-y:auto ;
    position: relative;
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
}
.containerSelectuser_Prev_Open{
    left: 0;
    animation: openuser_Prev 0.2s linear;
    opacity: 1;
}
.containerSelectuser_Prev_close{
    left: -100%;
    animation: closeduser_Prev 0.3s linear;
    opacity: 0;
}

@keyframes openuser_Prev {
    0%{
        left: -100%;
        opacity: 0;
    }
}
@keyframes closeduser_Prev {
    0%{
        left: 0;
        opacity: 1;
    }
}
.userHover_Select{
    
    cursor: pointer;
    transition: all 0.2s linear;
}
.userHover_Select:hover{
    background-color: rgba(12, 16, 39, 0.162);
}

.dadosOsPendente{
    width: 100%;
    max-height: 350px;
    border-radius: 5px;
    animation: openTdOs 0.5s linear;
    overflow-x: hidden;
    overflow-y: auto;
}

@keyframes openTdOs {
    0%{
        max-height: 0px;
    }
}