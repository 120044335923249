.configBlack{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    box-sizing: border-box;
    z-index: 5;
}

.configWhite{
    width: 100%;
    padding: 12px 12px;
    box-sizing: border-box;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    margin: 0 auto;
    display: block;
    background-color: white;
    position: absolute;
    bottom: 0;
    max-width: 600px;
    max-height: 90%;
    overflow-x: hidden;

}
.congif_select{
    border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    height: 30px;
    width: 90px;
    padding: 5px;
}

.configBtn{
    width: 50%;
    max-width: 150px;
    border: none;
    height: 30px;
    background-color:rgba(0,0,0,0.1);
    transition: all 0.15s linear;
}

.configBtn:hover{
    background-color:rgba(0,0,0,0.2);
}
