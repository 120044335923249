.spn_contianer{
    width: 100%;
    height: 100%;
    background-color: calc();
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: transparent;
    border-radius: 5px;
    box-sizing: border-box;
    position: absolute;
}
.spn_circulo{
    width:25px;
    height: 25px;
    border-radius:50% ;
    border: 3px solid #ffffff81;
    border-bottom: 3px solid #1263dd;
    background-color: transparent;
    animation: btnspn 0.6s linear infinite;
    box-sizing: border-box;
}
@keyframes btnspn {
    0%{
        transform: rotate(-10deg);
    }100%{
        transform: rotate(360deg);
    }
}