.MG_black{
    width: 100vw;
    height: 100%;
    display: flex;
    position: fixed;
    top:0;
    z-index: 10;
    justify-content: center;
    align-items: center;
}
.MG_blackopen{
    background-color: rgba(0, 0, 0, 0.7);
    animation: closeOpen 0.3s linear;
}
.MG_blackclose{
    background-color: rgba(0, 0, 0, 0.0);
    animation: closeMenu 0.3s linear;
}

@keyframes closeMenu {
    0%{
        background-color: rgba(0, 0, 0, 0.8);
    } 
}
@keyframes closeOpen {
    0%{
        background-color: rgba(0, 0, 0, 0.0);
    } 
}

.MG_container01{
    position: absolute; 
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    box-sizing: border-box;
    padding-bottom: 15px;
}
.MG_container01_open{
    bottom: 0;
    opacity: 1;
    animation: MgOpen 0.3s ease-in-out;
}
.MG_container01_close{
    bottom: -25%;
    opacity: 0;
    animation: MgClose 0.3s ease-in-out;
}

@keyframes MgOpen {
    0%{
        bottom:-25%;
        opacity: 0;
    }
}
@keyframes MgClose {
    0%{
        bottom:0;
        opacity: 1;
    }
}


.MG_container02{
    width: 100%;
    height: 100%;
    padding: 15px 25px;

    margin: 0 auto;
    box-sizing: border-box;
    display: block;
}
.MgUser{
    margin-top: 15px;
    font-weight: 700;
    font-size: large;

}
.MG_menulista{
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    margin-top: 20px;
}
.MG_menulista svg:nth-child(1){
    fill: #212121;
    min-width: 26px;
    max-width: 26px;
    font-weight: 600;
    margin-right: 8px;
    height: 100%;
}

.Geral_items_Style svg:nth-child(2){
    position: absolute;
    right: 0;
    fill: rgb(41, 234, 108);
    min-width: 20px;
    max-width: 20px;
}

.Geral_items_Style{
    width: 100%;
    padding: 12px 0;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    border:none;
    border-bottom:1px solid rgba(0, 0, 0, 0.368);
    position: relative;
    cursor:pointer;
}