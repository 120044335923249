@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");

.back_Container_home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Super_Container_home {
  position: absolute;
  top: 0;
  width: 100%;
  height: 35%;
  border-bottom-right-radius: 30%;
  border-bottom-left-radius: 30%;
  background-color: rgb(13, 224, 112);
  display: block;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}
.Bemvindo_user {
  width: 95%;
  margin: 0 auto;
  margin-top: 8px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 11px;
  box-sizing: border-box;
}
.svg_user {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.svg_user svg {
  fill: #262626;
}
.bm_Txt {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #262626;
}
.Dados_user {
  display: block;
  font-family: "Roboto", sans-serif;
}

/* Configuração do menu */

.containerSelectMEnu {
  width: 95%;
  margin: 0 auto;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.select_ct:hover {
  background-color: #969696;
  transform: scale(1.03);
}

.select_ct {
  font-family: "Poppins", sans-serif;
  width: 95%;
  min-width: 200px;
  max-width: 300px;
  height: 80px;
  display: block;
  position: relative;
  /*   border:1px solid #292929; */
  box-sizing: border-box;
  margin: 5px;
  border-radius: 10px;
  flex: 1;
  background-color: #cfcfcf;
  opacity: 0.8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.icon {
  width: 40%;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.select_ct svg {
  width: 50px;
  height: 50px;
  fill: #292929;
}

.NameDeficao {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 60%;
}
.NameDeficao h2,
span {
  margin-top: 0;
  margin-bottom: 0;
}
.NameDeficao span {
  margin-top: -30px;
}


/* Container de parametro das maquinas */


.preMaquinas{
  width: 100%;
  height: 100%;
  position: fixed;
  box-sizing: border-box;
  z-index: 1;

}

.prOpen{
  background-color: #000000db;
  animation: bakcopenprAnimation 0.18s linear;
}
.prClosed{
  background-color: #00000000;
  animation: bakcloseprAnimation 0.18s linear;
}

@keyframes bakcopenprAnimation {
  0%{
    background-color: #00000000;
  }
}
@keyframes bakcloseprAnimation {
  0%{
    background-color: #000000db;
  }
}

.dadosPr{
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius:15px;
  border-top-right-radius: 15px;
  padding: 15px;
  position: absolute;
  flex-direction: column;
  display: flex;
}

.dadosPrOpen{
  background-color: white;
  bottom: 0;
  animation: openPranim 0.2s ease-in-out;
  opacity: 1;
}

.dadosPrClosed{
  background-color: white;
  bottom: -10px;
  animation: closedPranim 0.2s ease-in-out;
  opacity: 0;
}

@keyframes openPranim {
  0%{
    bottom: -10px;
    opacity: 0;
  }
}
@keyframes closedPranim {
  0%{
    bottom: 0;
    opacity: 1;
  }
}