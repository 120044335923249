.containerAnalise{
    width: 100%;
    max-width: 1000px;
    background-color: rgb(235, 235, 235);
    box-sizing: border-box;
    margin: 0 auto;
    padding-bottom: 70px;
    display: block;
    transition: all 0.5s ease-in-out;
}


.analise_CTN1{
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    margin-bottom:5px ;
    position: relative;
}

.analise_CTN2{
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(255, 0, 0, 0);

    display: block;
    margin-bottom:5px ;
}

.analise_CTN3{
    width: 100%;
    box-sizing: border-box;

    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -38px;
    margin-bottom:5px ;

}

.analise_CTN4{
    width: 95%;
    box-sizing: border-box;
    padding: 10px;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: white;
    border-radius: 5px;
}

.analise_CTN5{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 5px;

}
.grafico_Ctn01{
    flex:1;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: block;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: white;
    border-radius: 5px;
    margin: 10px;
    min-width: 350px;
    max-width: 500px;
}
.vermelhoMeta{
    background: linear-gradient(to Bottom, rgba(255, 132, 0, 0.8), rgb(255, 4, 0, 0.753));
    color: white;
}
/* background: linear-gradient(to Bottom, #ff5900cc, #ff0400c0); */
.verdeMeta{
    background: linear-gradient(to Bottom, rgba(0, 255, 55, 0.631), #00ff95ad);
    color: rgb(44, 44, 44);
}
/* background: linear-gradient(to Bottom, #00ff377d, #00ff95ad); */
.ctn01{
    width: 100%;
    display: flex;
    
}

/* Filtro menu  */


.filtroMenu{
    position: relative;
    width: 100%;
    max-width: 1000px;
    background-color: white;
    box-sizing: border-box;
    margin: 0 auto;
}
.filtroMenu_Open{
    top:0;
    animation: openMenu 0.5s ease-in-out;
}

.turnoanalitics{
    width:30%;
    border:1px solid rgba(0, 0, 0, 0.5);
    border-radius:5px;
    display:flex;
    min-width: 30px;
    justify-content:center;
    align-items:center;
    font-weight:600;
    cursor: pointer;
    margin: 2px;
}

.filtroMenu_Close{
    top:-100px;
    animation: closeMenu 0.5s ease-in-out;

}
@keyframes openMenu {
    0%{
        top:-50px;
    }
}
@keyframes closeMenu {
    0%{
        top:0px;
    }
}

.inputDate{
    width: 50%;
    border: none;
    background-color: white;
    outline: none;
    height: 25px;
}
.selectTurno{
    background: linear-gradient(to Bottom, rgba(0, 255, 55, 0.631), #00ff95ad);
    box-shadow: 0 2PX 5px rgba(0, 0, 0, 0.3);
    transform: scale(1.08);
    transition: all 0.2s ease-in-out;
}

.animationHoverGr{
    background-color: white;
    transition: all 0.2s ease-in-out;
}
.animationHoverGr:hover{
    background: linear-gradient(to right, rgba(68, 255, 109, 0.338),rgba(0, 183, 255, 0.211));
    overflow: hidden;
    padding:5px;
    
}

.buttonPesquisar{
    max-width: 120px;
    min-width: 100px;
    width: 70%;
    height: 40px;
    margin: 0 auto;
    border:none;
    background:linear-gradient(to left,rgb(17, 164, 243),rgba(12, 147, 193, 0.907));
    border-radius:10px ;
    color:white !important;
    transition: all 0.15s linear;
}

.buttonPesquisar:hover{
    box-shadow: 0 0 5px 1px rgba(140, 137, 137, 0.536);
    cursor:pointer;
    transform: scale(1.01);
}
.clickBarra_Animation{
    background-color:rgba(0,0,0,0.05);
}


.clickBarra_Animation:hover{
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
transition: all 0.1s linear;
cursor: pointer;
transform: scale(1.05);
background-color: rgb(0, 255, 162);
}



