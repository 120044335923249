
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    box-sizing: border-box;
}

.prevContainer01{
    font-family: "Montserrat", sans-serif;
    width: 100%;
    height: 100vh;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
};

.prevContainer02{
    height: 35%;
    width: 100%;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    background-color: rgb(222, 241, 254);/* bola designer */
}

.prevContainerPrincipal01{
    width: 100%;
    height: 100%;
    max-width: 800px;
    position: absolute;
    padding: 15px;
    margin: 0 auto;
}
.Prev_Sesion01{
    width: 100%;
    display: flex;
}

.Prev_sesionCartao{
    width: 100%;
    position: relative;
    border-radius: 15px;
    display: flex;
    background-color: rgb(192, 249, 183);
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow: hidden;

}
.circuloDesigner{
    width: 160px;
    height: 160px;
    border-radius: 100%;
    position: absolute;
    background:linear-gradient(to right, rgb(197, 252, 189),rgb(189, 250, 180), rgb(157, 246, 143));
    z-index: 0;
    right: 20px;
}
.btnIniciarPrevs{
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    background-color: rgb(120, 208, 249);
}

/*  progresse circulo div */


.ResumoPrev{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    margin-top: 20px;
}

.containerResumoPrev{
    width: 50%;
    height: 80px;
    border: none;
    background-color: rgb(254, 248, 222);
    border-radius: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 7px;
    font-size: clamp(12px,1.8vw,15px);
}
.containerResumoPrev div:nth-child(2){
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    font-size: 18px;
    margin-top: -6px;
    margin-bottom: 5px;
}
.containerResumoPrev div:nth-child(3){
    display: flex;
    justify-content: flex-end;
}

.ResumoPrev .containerResumoPrev:nth-child(2){
    margin-left: 5px;
    margin-right: 5px;
    background-color: rgb(254, 222, 222);
}
.ResumoPrev .containerResumoPrev:nth-child(3){
    background-color: rgb(223, 254, 222);
}

.containerTodasPrev{
    margin-top: 15px;
    border-radius: 7px;
    width: 100%;
    padding: 5px;
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    
}

.custom-dots div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .custom-dots div.active {
    background-color: #ec4f4f; /* Cor escura para o dot selecionado */
    color: #b62626; /* Cor do texto do dot selecionado */
  }
  .slick-dots span {
    color: #000; /* Cor dos números */
  }

  .slick-dots span.active {
    font-weight: bold; /* Estilo para o número do dot selecionado */
  }


  /* Treinamento */


  