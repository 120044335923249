.Apont_body{
    width: 100%;
    position: relative;
    display: block;
    background-color: #ffffff;
    bottom: 0;
    
}
.apont_body2{
    position: relative;
    width: 100%;
    background-color:rgb(255, 255, 255);
    box-sizing: border-box;
}
.blueContainer{
    width: 100%;
    height: 25vh;
    position: absolute;
    top:0;
    background-color: #06283a;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
}
.apont_container01{
    width: 100%;
    max-width: 800px;
    padding: 10px 8px;
    margin: 0 auto;
/*     border: 2px solid red !important; */
box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    display: block !important;
    box-sizing: border-box;
    overflow-y: auto ;
    color: #ffffff;
}
.apont_selectinfo{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px !important;
    background-color:rgba(36, 73, 92, 0.669);
    border-radius: 15px;
    position: relative;
    z-index: 9999999;
}
.apont_select{
    color: rgb(212, 212, 212);
    width: 50%;
    height: 40px;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    margin:2px;
   
}

.apont_individual{
    background-color: rgb(13, 224, 112);
    color: #212121;
    border: 1px solid rgb(248, 248, 248);
    animation: selectGrIp 0.2s ease-in-out;
    font-size: 17px;
}
.apont_geral{
    background-color: rgb(13, 224, 112);
    color: #212121;
    border: 1px solid rgb(248, 248, 248);
    animation: selectGrIp 0.2s ease-in-out;
    font-size: 17px;
}

@keyframes selectGrIp {
    0%{

        width: 35%;
        font-size: large;
    }
}

.Apont_container_chart01{
width: 100%;
box-sizing: border-box;
/* border: 1px solid red; */
margin-top: 15px;
display: flex;
justify-content: center;
align-items: row;
flex-wrap: wrap;
}

.Apont_chart01{
    color: #212121;
    width: 40vw;
    max-width: 200px;
    min-width: 130px;
    height: 175px;
    margin:8px;
    flex:1;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    box-shadow: 0 0px 6px rgba(7, 41, 59, 0.22);
    box-sizing: border-box;
    display: block;
    position: relative;
    overflow: hidden;
}
.apont_dash01{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.apont_dash01 h4{
    margin: 0;
    font-weight: 600;
}
.apont_dash01 svg{
    fill: #212121e1;
    width: 25px;
    height: 25px;
    margin-right: 2px;
}
.comportGraficoApontameto{
    width: 100%;
    height: 80%;
    padding-bottom: 5%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dadosApont_hoje{
    margin: 0 auto;
    text-align: center;
    width: 80%;
    position: absolute;
    font-size: 12px;
    display: block;

}
.Label_tarefa{
    margin: 0 auto;
    text-align: left;
    width: 45%;
    position: absolute;
    font-size: 12px;
    display: block; 
    margin-top: 12px;
}
.Label_tarefa div:nth-child(1){
    color: rgb(0,150,255)
}
.Label_tarefa div:nth-child(2){
    color: rgb(247, 71, 71)
}
.Label_tarefa div:nth-child(3){
    color: rgb(13, 224, 112);
}

.infor_Apont{
    border: none;;
    width: 80%;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 8px;
    border-radius: 10px;
    color: #ffffff;
    padding: 5px 15px;
    background-color: rgb(3, 26, 38);
}

.select_Apont_PreTf{
    margin: 0 auto;
    width: 98%;
    height: 40px;
    background-color: #f9f9f9;
/*     border: 1px solid red; */
    margin-top: 15px;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0 5px 5px rgba(7, 41, 59, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.apont_select_Tf{
    width: 50%;
    height: 100%;
    border-radius: 15px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #212121;
}

.select_Preventiva{
    color: white;
    background-color: rgb(13, 224, 112);
    animation: selectGrIp 0.2s ease-in-out;
}

.select_Tarefa{
    color: white;
    background-color: rgb(13, 224, 112);
    animation: selectGrIp 0.2s ease-in-out;
}


/* ediç'ão dos compenentes das preventivas e tarefas */

.ApontContainer_Preventiva{
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
/*     border: 1px solid rgb(255, 0, 195); */
    display: block !important;
    position: relative !important;
    box-sizing: border-box;
    color:#212121;
}

.apont_item01{
    width: 98%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex !important;
    overflow: hidden !important;
}

.PrevPendente_item{
    width: 95%;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: -15px;
    overflow-x:hidden;
    overflow-y:auto  ;
    display: none;
}

.PrevPendente_item_open{
    display: block;
    max-height: 500px;

    animation: openPreventiva 0.5s linear;
}
.PrevPendente_item_close{
    display: block;
    max-height: 0;
    animation: closePreventiva 0.5s linear;
}

@keyframes closePreventiva {
    0%{
        max-height: 250px;
    }
}
@keyframes openPreventiva {
    0%{
        max-height: 0;
    }
}

.TodasPrev_e_Tarefas{
    width: 90%;
    padding: 5px 12px;
    margin: 0 auto;
    margin-bottom: 5px;
    margin-top: 5px;
    display: block;
    box-sizing: border-box;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
.TodasPrev_e_Tarefas1{
    width: 97%;
    padding: 5px 10px;
    padding-top: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 5px;
    display: block;
    box-sizing: border-box;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;   
}

.clickTrue{
    position: relative;
    border:2px solid transparent;
    border-left:3px solid red;
    background-color:rgba(0,0,0,0.3);
    width:50px;
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    animation: spinerClick 1.5s linear infinite;
}
@keyframes spinerClick {
    0%{
        transform: rotate(-360deg);
    }
}

/* Estilizaççao do menu Geral para analise de apontamento */

.Geral_Container01{
    color:#ffffff;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    display: block;
    background-color: #06283a;
    box-shadow: 0 4px 5px rgba(0,0,0,0.3);
    padding: 15px 15px;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 500px;
    position: relative;
    z-index: 1;
}
.Geral_Container02{
    color:#212121;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top:25px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    text-indent: 5px;
    position:relative;
}

.Container_Geral03{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between; /* Ou qualquer outra opção de alinhamento */
    align-items: stretch; /* Estica os itens verticalmente */
    margin-top: 5px;
    flex-wrap: wrap;
}

.BtnConsultar{
    width: 50%;
    max-width: 150px;
    height: 35px;
    background-color: #06283a;
    color: white;
    border: none;
    border-radius: 50px;
    margin-top: 15px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
.BtnConsultar:hover{
    background-color: #0a3b55;
}

.editInputsConsulta{
    width: 50%;
    display: flex;
    flex-direction: column;
    color:#212121;
    position: relative;
    box-sizing: border-box;
    max-width: 220px;
}

.editInputsConsulta input, .editInputsConsulta select{
    border: none;
    color: #212121;
    height: 30px;
    border-bottom: 1px solid #212121;
    margin:5px;
    outline:'none';
    transition: all 0.2s linear;
    max-width: 120px;
}
.editInputsConsulta input:hover,select:hover{
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.btnEnviarConsulta{
    width: 50%;
    max-width: 150px;
    min-width: 100px;
    height: 35px;
    background-color: #cdcdcd;
    color: rgb(39, 39, 39);
    border: none;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.Impresso{
    border:1px solid rgba(0,0,0,0.2);
}



@media print {
    body * {
        visibility: hidden;
    }

    .Impresso, .Impresso * {
        visibility: visible;
    }

    .Impresso {
        position: absolute;
        left: 0;
        top: 0;
        border: 1px solid rgba(0, 0, 0, 0.0);
    }
}

.loadPrevPub{
    border-radius:100%;
    width:58px;
    height:58px;
    background-color:rgba(50, 49, 49, 0.25);
    position:absolute;
    display: none; 
    border-width: 1px;
}
.loadtruePrevPub{
    display: flex; 
    animation: clickPrevIniciar 0.22s ease-in-out;
}
@keyframes clickPrevIniciar {
    0%{
        background-color: rgba(121, 121, 121, 0.055);
        width:2px;
        height:2px; 
    }
}
.loadPrev{
   border-radius:100%;
   width:48px;
   height:48px;
   border:2px solid rgba(0,0,0,0.1);
   position:absolute;
   display: none;
}

.loadtruePrev{
    display: flex;
    border-left:2px solid rgb(20, 110, 240);
    border-right:2px solid rgb(20, 110, 240);
    border-top:2px solid rgb(20, 110, 240);
    animation: spinLoader_prev 0.7s linear infinite;
}

@keyframes spinLoader_prev {
    0%{
        transform: rotate(360deg);
    }
}


/* container de preventiva finalizada */

.FinalizaPrev_black{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.openBlack_Finaliza{
    background-color: rgba(0, 0, 0, 0.7);
    animation: openblack 0.3s linear;
}
.closedBlack_Finaliza{
    background-color: rgba(0, 0, 0, 0.0);
    animation: closeblack 0.3s ease-in-out;
}
@keyframes openblack {
    0%{
        background-color: rgba(0, 0, 0, 0.0);
    }
}
@keyframes closeblack {
    0%{
        background-color: rgba(0, 0, 0, 0.7);
    }
}

.FinalizaPrev_white{
    color: #212121;
    width: 100%;
    position: absolute;
    margin: 0 auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: block;
    background-color: white;
    padding: 10px 15px;
    box-sizing: border-box;
    max-width: 600px;
}
.FinalizaPrev_whiteOpen{
    bottom: 0;
    opacity: 1;
    animation: openwhiteFinaliza 0.3s ease-in-out;
}
.FinalizaPrev_whiteClose{
    bottom: -50%;
    opacity: 0;
    animation: closewhiteFinaliza 0.3s ease-in-out;
}
@keyframes openwhiteFinaliza {
    0%{
        opacity: 0;
        bottom: -50%;
    }
}
@keyframes closewhiteFinaliza {
    0%{
        opacity: 1;
        bottom: 0;
    }
}
.btnFinalizPrev{
    width: 50%;
    height: 35px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    background-color: rgb(13, 224, 112);
    transition: all 0.25s linear;
}

.btnFinalizPrev:hover{
    background-color: rgba(13, 224, 112,0.8);
}