.Black_Aberto_Os {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  animation: blackOsopen 0.3s linear;
  transition: background-color 0.3s linear;
  z-index: 1;
}
.BlackFechado {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.0);
  animation: blackOs 0.3s linear;
  transition: background-color 0.3s linear;
  z-index: 1;
}

@keyframes blackOs {
  0%{
    background-color: rgba(0, 0, 0, 0.8);
  }
}
@keyframes blackOsopen {
  0%{
    background-color: rgba(0, 0, 0, 0.0);
  }
}

.Fechar_Novaordem {
  transition: all 0.1s linear;
  border-radius: 5px;
}
.Fechar_Novaordem:hover {
  cursor: pointer;
  background-color: #26262625;
}

.NvContainer{
  display: none;
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
  background-color: #fffefe;
  position: fixed;
  max-height: 90vh;
  overflow: auto;
}

.aberto {
  display: block;
  top:0;
  opacity: 1;
  animation: fade-bottom 0.18s ease-in-out;
}


.fhc{
  opacity: 0;
  display: block;
  top:8%;
  animation: fade-top 0.2s ease-in-out;
}

@keyframes fade-top {
  0% {
    opacity: 1;
    top: 0; 
  }
}
@keyframes fade-bottom {
  0% {
    opacity: 0;
    top: 8%;
  }
}
.TituloOrdem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  margin-bottom: 5px;
}
.TituloOrdem svg {
  fill: #262626;
  margin-right: 5px;
}
.Container_Numberordem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  margin-top: 20px;
  position: relative;
  min-width: 120px;
  max-width: 180px;
  height: 25px;
  font-size: 14px;
  flex-wrap: wrap;
  flex: 1;

  /* font-size:clamp(11px,1.8vw,15px) ; */
}

.moveLabel {
  position: absolute;
  transition: all 0.3s linear;
  top: -15px;
  font-size: 12px;
  color: #383838;
  left: 2px;
}
.moveLabel_Normal {
  top: 5px;
  position: absolute;
  left: 2px;
  box-sizing: border-box;
  pointer-events: none;
  color: #262626;
  transition: all 0.3s linear;
}
.Container2_Ordem fieldset {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Container_Numberordem input,
.Container_Numberordem select {
  width: 100%;
  outline: none;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #262626;
  background-color: transparent;
  box-sizing: border-box;
}
.Container_Numberordem select {
  font-size: 10px;
}
.option_Ordem {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.option_Ordem input {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  opacity: 0;
}
.option_Ordem {
  pointer-events: all;
}
.colorSelected {
  width: 100%;
  background-color: #ebf21b;
  animation: sl 0.25s ease-in-out;
  font-weight: 700;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.lb {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MqParou {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #26262667;
  border-radius: 5px;
  margin-right: 2px;
  margin-left: 2px;
  overflow: hidden;
}
@keyframes sl {
  0%,
  1% {
    width: 30%;
    border-radius: 100%;
    font-size: large;
  }
}
.mQ_PAROU_Option {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.mQ_PAROU_Option label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_registrar_Ordem {
  margin: 10px;
  width: 50%;
  max-width: 150px;
  height: 100%;
  outline: none;
  border: none;
  background-color: #262626;
  color: white !important;
  border-radius: 5px;
}

/* Tabela ordem que foram aberta */

.Container_Os_Ordem {
  width: 98%;
  padding: 2px;
  border: 1px solid red !important;
  margin: 0 auto;
  margin-bottom: 5px;
  box-sizing: border-box;
  display: block;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: auto;
}

.dadosOs_Ordem {
  width: 95%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  border-radius: 5px;
  max-width: 600px;
  min-width: 200px;
  overflow: hidden;
  display: flex;
  margin-bottom: 6px;
  transition: all 0.2s linear;
}

.Ordem_Maq {
  font-size: clamp(13px, 1.8vw, 15px);
  color: white !important;
  font-weight: 700;
  height: calc();
  width: 20%;
  max-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ordem_Container_Dados {
  font-size: clamp(11px, 1.8vw, 13px);
  width: 100%;
  background-color: #dcdcdc;
  display: flex;
  justify-content: stretch;
  align-items: row;
  flex-wrap: wrap;

}

.dadosOs_Ordem:hover{
  transform: scale(1.02);
  background-color: rgba(13, 224, 112,0.2);
  cursor: pointer;
}

.Ordem_Container_Dados div {
  min-width: 100px;
  flex: 1;
  margin: 2px;
  height: calc();
  justify-content: center;
  align-items: center;
}
.Ordem_Container_Dados div:nth-child(3) {
  font-size: 10px;
}


/* Popup para parar a mesma Os */

.black_NvParada{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  animation: NvBlackOs_open 0.3s linear;
  position: relative;
}
.black_NvParada_Fechar{
  position:relative;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 2;
  animation: NvBlackOs 0.3s linear;
}
@keyframes NvBlackOs {
  0%{
    background-color: rgba(0, 0, 0, 0.8);
  }
}
@keyframes NvBlackOs_open {
  0%{
    background-color: rgba(0, 0, 0, 0.0);
  }
}
.Container_NvParada{
  top:25px;
  width: 85%;
  min-width: 120px;
  max-height: 50vh;
  max-width: 400px;
  background-color: white;
  display: block;
  border-radius: 8px;
  padding: 3%;
  position: relative;
  animation: fadeOpen 0.3s ease-in-out;
}

.Container_NvParada_close{
  top:100%;
  width: 85%;
  min-width: 120px;
  max-height: 50vh;
  max-width: 400px;
  background-color: white;
  display: block;
  border-radius: 8px;
  padding: 3%;
  position: relative;
  animation: fadeclose 0.3s ease-in-out;
}

@keyframes fadeOpen {
  0%{
    top:100%;
  }
}
@keyframes fadeclose {
  0%{
    top:25px;
  }
}

.Titulo_NvParada{
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  border-bottom: 1px solid rgb(103, 103, 103);
  font-size: clamp(15px,1.8vw,18px);
}
.Titulo_NvParada svg{
  margin-right: 8px;
}
.Nv_MaquinaSelect{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  background-color: rgb(13, 224, 112);
  box-sizing: border-box;
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 8px;
  color: rgb(0, 0, 0);
}

.tituloNv{
  display: inline-flex;
  align-items: center;
  font-size: larger;
  border-bottom: 5px solid rgb(13, 224, 112);
  box-sizing: border-box;
}


.Nv_ContainerOs{
  width: 100%; 
}

.Nv_ContainerDados_Os{
  width: 100%;
 /*  border: 1px solid red; */
  position: relative;
  display: flex;
  margin-top: 20px;
  padding: 5px;
  transition: all 0.2s linear; 
}

.Nv_ContainerDados_Os div:nth-child(1){
  display: flex;
  justify-content: center;
  align-items: center;
}
.Nv_ContainerDados_Os:hover{
  border-radius: 30px;
  background-color: rgb(222, 245, 219);
  cursor: pointer;
}
.Nv_dadosOs_container{
  min-width: 75%;
  max-width: 75%;
  display: block;
  margin-left: 10px;
  font-size: clamp(11px, 1.8vw, 13px);
}
.Nv_dadosOs_container div:nth-child(1){
  display: flex;
 justify-content: flex-start;
  margin-left: 10px;
  box-sizing: border-box;
  font-weight: 800;
}
.Nv_dadosOs_container div:nth-child(2){
  margin-left: 10px;
  box-sizing: border-box;
  margin-top: 8px;
}
.svgSet_Nv{
  height: calc();
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
 
  fill: rgb(13, 224, 112);
}
.Nv_dadosOs_container div:nth-child(3){
  margin-left: 10px;
  box-sizing: border-box;
  margin-top: 8px;
  font-size: clamp(12px, 1.8vw,15px);
  font-weight: 800;
  border: none;
}

.container_btn_Nv{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.container_btn_Nv button{
  width: 50%;
  height: 35px;
  margin: 5px;
  border-radius: 25px;
  border: none;
  box-shadow: 0 5px 5px rgba(71, 71, 71, 0.123);
}

.container_btn_Nv button:nth-child(1){
  background-color: rgb(124, 23, 186);
  color: white;
  transition: all 0.2s linear;
}
.container_btn_Nv button:nth-child(1):hover{
  background-color: rgb(89, 22, 131);
  cursor: pointer;
}

.container_btn_Nv button:nth-child(2){
  background-color: rgb(232, 29, 29);
  color: white;
  transition: all 0.2s linear;
}
.container_btn_Nv button:nth-child(2):hover{
  background-color: rgb(195, 59, 59);
  cursor: pointer;
}


@keyframes frmaeOs_select {
  0%{
    background-color: rgba(151, 252, 129, 0.0);
    border-radius: 30%;
  }
}