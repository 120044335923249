.Container_MenuPhone {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:rgb(3, 26, 38);
  position: fixed;
  bottom: 0;
  box-shadow: 0 -2px 7px rgba(255, 255, 255, 0.3) ;
  z-index: 1;
  box-sizing: border-box;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  overflow: hidden;
}

.Container_MenuPhone {
  fill: rgb(255, 255, 255) !important;
}

.container_icon_Menu {
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  max-height: 50px;
  display: block !important;
  background-color:rgb(3, 26, 38);
  margin: 0 auto;
  color: rgb(255, 255, 255) !important;


  margin-bottom: 10px;
}

.select_Menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;
}
.select_Menu_color {
  width: 48%;
  background-color: rgba(20, 176, 103, 0.77);;
  border-radius: 20px;
  animation: ballColor 0.2s ease-in-out;
}
@keyframes ballColor {
  0%,
  0% {
    width: 30px;
    height: 30px;
  }
}

.ref_Menu {
  width: 100%;
  display: flex !important;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
}
