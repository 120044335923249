.black_FinalizarOrdem {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  transition: background-color 0.2s linear;
  animation: opc 0.3s linear;
}
.fecharFm{
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.0);
  top: 0;
  transition: background-color 0.2s linear;
  animation: opc2 0.2s linear;
}
@keyframes opc {
  0% {
    background-color: rgba(0, 0, 0, 0.0);
  }
}
@keyframes opc2 {
  0% {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.popupinciar_Manutencao{
  display: none;
  width: 100%;
  max-width: 800px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 7px;
  max-height: 100vh;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 2;
  box-sizing: border-box;

}

.Fo_Aberto {
  animation: abrirUp 0.15s ease-in-out;
  top: 0;
  opacity: 1;
  display: block;
}

.Fo_Fechado {
  display: block;
  top: 8%;
  animation: fecharUp 0.15s ease-in-out;
  opacity: 0;
}

@keyframes abrirUp {
  0% {
    opacity: 0;
    top: 8%;
  }
}

@keyframes fecharUp {
  0% {
    opacity: 1;
    top: 0;
  }
}

.FO_TopoTxt {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;

}
.Tree_container_FO {
  width: 50%;
  max-width: 200px;
  box-sizing: content-box;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  border: 1px solid;
  font-weight: 800;
  border-radius: 20px;
  font-size: clamp(17px, 1.8vw, 20px);
}


.cinco_container_FO {
  width: 100%;
  box-sizing: border-box;
  margin-top: 2px;
}
.FO_Historico {
  display: block;
  padding: 5px;
  border-radius: 10px;
}
.FO_Historico {
  box-sizing: border-box;
  font-size: clamp(13px, 1.8vw, 15px);
}

.seven_container_FO {
  width: 100%;
  margin: 0 auto;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;

  box-sizing: border-box;
}
.seven_container_FO button, .Pausar_fim {
  width: 50%;
  max-width: 200px;
  border: none !important;
  border-radius: 30px;
  height: 40px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: 300;
  position: relative;
  /*   background-color: #EBF21B; */
  background-color: rgb(13, 224, 112);
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 5px #2626263d;
  transition: background-color 0.15s linear;
  margin-top: 12px;
}
.seven_container_FO svg {
  margin-right: 10px;
}

.seven_container_FO select {
  max-width: 150px;
  width: 50%;
  outline: none;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #262626;
  background-color: transparent;
  box-sizing: border-box;
  margin-right: 20px;
  text-align: center;
}

.FO_Btn:hover {
  background-color: rgb(33, 190, 106);
  cursor: pointer;
}

.oito_container_Fo {
  width: 100%;
  max-height: 35vh;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  justify-content: row;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
}
.Container_Mec_Fo {
  width: 50%;
  max-width: 20vw;
  min-width: 155px;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(55, 55, 55, 0.551);
  margin: 5px;
  flex: 1;
  display: block;
  position: relative;
}

.FO_userIn {
  width: 70%;
  height: 21px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 224, 111, 0.764);
  font-size: clamp(13px, 1.8vw, 15px);
  font-weight: 300;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  box-shadow: 0 1px 5px rgba(55, 55, 55, 0.3);
}
.Container_Mec_Fo div:nth-child(2),
.Container_Mec_Fo div:nth-child(3),
.Container_Mec_Fo div:nth-child(4),
.Container_Mec_Fo div:nth-child(5) {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 3px;
  margin-bottom: 4px;
  box-sizing: border-box;
  color: #262626;
  font-size: clamp(12px, 1.8vw, 13px);
  font-weight: 300;
}
.Container_Mec_Fo div:nth-child(3) {
  font-size: clamp(10px, 1.8vw, 12px);
  font-weight: 300;
}
.Container_Mec_Fo svg {
  fill: #262626;
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.Btn_Pause_Im {
  width: 50%;
  height: 25px;
  max-width: 100px;
  border: none !important;
  border-radius: 10px;
  color: #262626;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #ff711930;
  font-weight: 300;
  transition: all 0.18s linear;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.Btn_Pause_Im:hover{
    cursor: pointer !important;
    background-color: #ebf21b72;

}


/* Apartir daqui é usado o container PAUSE MANUTENÇÃO */


.blck_PauseMan{
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  transition: background-color 0.2s linear;
  animation: opc 0.15s linear;
}

.blck_PauseMan_fecha{
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.0);
  top: 0;
  transition: background-color 0.12s linear;
  animation: opc2 0.2s linear;
}

.second_Pause_Man{
  display: block;
  bottom: 1px;
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  padding: 5px;
  max-height: 95vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  animation: pauseManEfectTop 0.4s ease-in-out;
}
.second_Pause_Man_fecha{
  display: block;
  bottom: -50%;
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  padding: 5px;
  max-height: 95vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  animation: pauseManEfectTop_fecha 0.4s ease-in-out;
}
@keyframes pauseManEfectTop {
  0%{
    bottom: -60%;
  }
}

@keyframes pauseManEfectTop_fecha {
  0%{
    bottom: 0;
  }
}
textarea{
  font-family:'Roboto', sans-serif;
}

.simSElect{
  width: 100%;
  border-radius: 35px;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  animation: seleSelibe 0.15s linear ;
}
@keyframes seleSelibe {
  0%{
    width: 2px;
    height: 2px;
    border-radius:100% ;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    font-size: larger;
  }
}