.Container_Black {
  width: 100vw;
  height: 100vh;
  background-color: #031A26;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container_dadosUser {
  width: 95%;
  max-width: 800px;
  min-width: 350px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: block;
  position: fixed;
  top: 40px;
}
.Container_Icon {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #616161;
}
.icon_Circulo_container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -35px;
  margin-left: 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}
.icon_Circulo_container svg {
  fill: #212121;
}
.ContainerForm {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* .Form_Conainer{
    width:98%;
    margin:0 auto;
    display:flex;
    overflow-x:hidden;
    overflow-y:auto;
    flex-wrap:wrap;
  } */

.Form_Conainer fieldset:nth-child(1) {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  border: none;

  border-radius: 5px;
}
.Form_Conainer fieldset:nth-child(2) {
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.Pai_chekbox {
  max-width: 150px;
  min-width: 150px;
  margin: 5px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
  padding: 3px;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
}
.Form_Conainer legend {
  background-color: #212121;
  color: white;
  border-radius: 4px;
}
.Form_Conainer legend h3 {
  margin: 0 auto;
  padding: 2px;
}

.inputs_class {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 5px;
  margin-top: 15px;
  flex: 1;
  min-width: 120px;
  max-width: 150px;
}

.topHover {
  position: absolute;
  top: -15px;
  font-size: 14px;
  color: #212121;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.topNormal {
  top: 0;
  position: absolute;
  font-size: 16px;
  color: #2b2b2b;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.Pswtop {
  position: absolute;
  top: -15px;
  font-size: 14px;
  color: #212121;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.Pswnormal {
  top: 0;
  position: absolute;
  font-size: 16px;
  color: #2b2b2b;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.TrTop {
  position: absolute;
  top: -15px;
  font-size: 14px;
  color: #212121;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.TrNormal {
  top: 0;
  position: absolute;
  font-size: 16px;
  color: #2b2b2b;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.Crachatop {
  position: absolute;
  top: -15px;
  font-size: 14px;
  color: #212121;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.crachaNormal {
  top: 0;
  position: absolute;
  font-size: 16px;
  color: #2b2b2b;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.Hrinciotop {
  position: absolute;
  top: -15px;
  font-size: 14px;
  color: #212121;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.hrinicioNormal {
  top: 0;
  left: 30%;
  position: absolute;
  font-size: 16px;
  color: #2b2b2b;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  width: 100%;
}
.HrFimtop {
  position: absolute;
  top: -15px;
  font-size: 14px;
  color: #212121;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.hrFimNormal {
  top: 0;
  left: 30%;
  position: absolute;
  font-size: 16px;
  color: #2b2b2b;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  width: 100%;
}

.inputs_class2 {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}

.Form_Conainer input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #212121;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.Form_Conainer select {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #212121;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.second_Fildset .inputs_class label {
  min-width: 170px;
  max-width: 180px;
  text-indent: 5px;
}

.second_Fildset .inputs_class {
  min-width: 170px;
  max-width: 180px;
  flex: 1;
}
.container_Button {
  width: 100%;
  margin: 0 auto;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.button_Registro {
  width: 50%;
  max-width: 180px;
  border-radius: 5px;
  height: 40px;
  background-color: #212121;
  border: none;
  color: #fff;
}

.Select_Type_Container {
  width: 100%;
  margin: 0 auto;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  box-sizing: border-box;
  border-radius: 7px;
}
.Selct_Type {
  width: 70%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}
.adduser_select,
.Editar_User {
  width: 100%;
  margin: 0 auto;
  height: 35px;
  background-color: #212121;
  border: none;
  color: #fff;
  border-radius: 15px;
}
.container_btnAdd {
  position: absolute;
  z-index: 2;
  margin: 5px;
}
.exit_addUser,
.AddUser {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
}
.exit_addUser:hover {
  background-color: #21212128;
}
.AddUser:hover {
  background-color: #21212128;
}
.exit_addUser svg {
  fill: #262626;
}
